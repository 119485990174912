import React from 'react';
import styled from 'styled-components';
import theme from './../theme';

const Card = styled.article`
  box-shadow: 0px 4px 20px rgba(255, 116, 175, 0.25);
  border-radius: 7px;
  background: white;
  padding: 25px;
  transition: box-shadow 100ms linear;

  &:hover {
    box-shadow: 4px 4px 30px rgba(236, 0, 100, 0.25);

    & + span {
      color: black;
      opacity: 1;
    }
  }
`;

const Title = styled.h4`
  color: black;
  font-size: 15px;
  font-family: ${theme.fontFamilies.sans_serif};
  font-weight: bold;
  margin-bottom: 0.7em;
`;
const Content = styled.p`
  color: black;
  font-size: 14px;
  font-family: ${theme.fontFamilies.sans_serif};
  line-height: 1.4em;
  margin-bottom: 0;
`;
const Cta = styled.span`
  display: block;
  text-align: center;
  padding: 0.5rem 1rem 2rem;
  font-size: 12px;
  transition: opacity 100ms linear;
  opacity: 0;
`;

const Box = ({ imageUrl, title, content, link, cta }) => (
  <div onClick={() => window.open(link)} style={{ cursor: 'pointer' }}>
    <Card>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </Card>
    <Cta>{cta}</Cta>
  </div>
);

export default Box;
