import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  * {
      box-sizing: border-box;
    }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  strong, b {
    font-weight: 700;
  }
  
  body {
    font-family: 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  p, ul, li {
    font-family: Montserrat, 'Open Sans', Helvetica, Arial, sans-serif;
    line-height: 1.5;
  }

  p {
    margin: 0 0 1rem;
  }

  p a {
    border-bottom: 2px solid #111;
    color: inherit;
    text-decoration: none;
    transition: all .15s linear;

    &:hover {
      color: #4F18EA;
      border-bottom-color: #4F18EA;
    }
  }

  ul {
    margin: 1rem 0 1.5rem;
  }

  li {
    margin-bottom: .25rem;
  }

  h1, h2, h3, h4, h5 {
    font-weight: 400;
  }

  h2 {
    font-size: 2rem;
    line-height: 1.5;
    padding: 0;

    @media (min-width: 768px) {
      font-size: 3.125rem;
    }

    > span {
      display: block;
      font-size: .7em;
      font-weight: 300;
      margin-top: .5rem;
    }
  }
  ::selection { background: #FFFBD9; }
`;

export default GlobalStyles;
