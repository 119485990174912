export default {
  breakpoints: {
    xs: '320px',
    sm: '400px',
    md: '600px'
  },
  fontFamilies: {
    serif: "'Playfair Display', serif",
    sans_serif: "'Montserrat', sans-serif;"
  }
};
