import React from 'react';
import styled from 'styled-components';
import theme from './../theme';

// Components
import Box from './Box';

const Section = styled.div`
  @media (min-width: ${theme.breakpoints.md}) {
    display: flex;
    flex-wrap: wrap;
    div {
      flex-basis: 47%;
      margin-right: 5%;
      &:nth-child(odd) {
        margin-right: 0;
      }
    }
  }
`;

const Heading = styled.h2`
  font-size: 23px;
  font-family: ${theme.fontFamilies.serif};
  font-weight: bold;
  margin-bottom: 1rem;
  flex-basis: 100%;
`;

const projects = [
  {
    imageUrl: '',
    cta: 'view on Github',

    title: 'Gatsby Mercado Libre Source Plugin',
    content: 'Plugin that pulls products data from Mercado Libre into Gatsby’s GraphQL layer.',
    link: 'https://github.com/florantara/gatsby-source-mercadolibre'
  },
  {
    imageUrl: '',
    cta: 'view on Github',

    title: 'Gatsby Theme Mercado Libre Store',
    content:
      'Based on theme settings creates grid and detail screens with products from Mercado Libre.',
    link:
      'https://github.com/florantara/gatsby-theme-mercadolibre-store/blob/master/theme/README.md'
  },
  {
    imageUrl: '',
    cta: 'visit website',
    title: 'Dogs Life',
    content:
      "Work in Progress: Using my Gatsby plugin+theme I'm creating a website for some friends so they don't rely on an e-commerce platform.",
    link: 'https://dogslife.netlify.com/'
  }
];

const RecentProjects = () => (
  <Section>
    <Heading>Some Projects</Heading>

    {projects.map(project => {
      return (
        <Box
          key={project.title}
          imageUrl={project.imageUrl}
          cta={project.cta}
          title={project.title}
          content={project.content}
          link={project.link}
        />
      );
    })}
  </Section>
);

export default RecentProjects;
