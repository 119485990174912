import React, { useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import GlobalStyles from './components/globalStyles';
import theme from './theme';

// Components
import Header, { EmailLink } from './components/Header';
import About from './components/About';
import RecentProjects from './components/RecentProjects';

const fadeIn = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const AppWrapp = styled.div``;
const Main = styled.main`
  opacity: 0;
  transform: translateY(-20px);
  animation: ${fadeIn} 200ms 200ms linear forwards;

  margin-bottom: 53px;
  @media (min-width: ${theme.breakpoints.md}) {
    margin-bottom: 0;
    height: 100vh;
    display: grid;
    place-items: center;
  }
`;

const Container = styled.section`
  width: 100%;
  max-width: 580px;
`;

const Dock = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff9fa;
  section {
    margin: 0 auto;
    padding: 3rem 3rem;

    @media (min-width: ${theme.breakpoints.sm}) {
      padding: 3rem 2rem;
    }

    @media (min-width: ${theme.breakpoints.md}) {
      padding: 3rem 0;
    }
  }
`;

const Ctas = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  opacity: 0;
  transform: translateY(-20px);
  animation: ${fadeIn} 200ms 800ms linear forwards;

  @media (min-width: ${theme.breakpoints.md}) {
    display: none;
  }

  > a {
    margin-bottom: 0;
    padding: 20px 10px;
  }
`;

// const TextMeMobile = styled(TextMe)`
//   border-left: 1px solid #212121;
//   display: inline-block;
//   img {
//     position: relative;
//     top: -1px;
//     right: auto;
//     left: 5px;
//   }
// `;

const Footer = styled.footer`
  width: 100%;
  background: #111;
  padding: 10px;
  text-align: center;
  color: white;
  font-size: 11px;
`;

const NotificationBanner = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: indigo;
  color: lavender;
  padding: 15px;
  text-align: center;
  font-size: 14px;
  transform: translateY(-100%);
  transition: transform 100ms linear;

  &.show {
    transform: translateY(0);
  }
`;

function App() {
  const [showingBanner, toggleBanner] = useState(false);
  const [bannerText, setBannerText] = useState('');

  const emailRef = useRef(null);
  const copyEmailToClipboard = () => {
    emailRef.current.select();
    emailRef.current.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
    showNotificationBanner(`📃 E-mail copied to clipboard`);
  };

  const showNotificationBanner = text => {
    setBannerText(text);
    toggleBanner(true);
    setTimeout(() => {
      setBannerText('');
      toggleBanner(false);
    }, 1500);
  };

  return (
    <AppWrapp>
      <GlobalStyles />
      <NotificationBanner className={showingBanner ? 'show' : ''}>{bannerText}</NotificationBanner>
      <Main>
        <Container>
          <Header onShowEmailNotification={showNotificationBanner} />
          <About />
        </Container>
        <Dock>
          <Container>
            <RecentProjects />
          </Container>
        </Dock>
        <Footer>
          Made with{' '}
          <span role="img" aria-label="spread love">
            💓
          </span>{' '}
          and React, in Seattle.
        </Footer>
      </Main>
      <Ctas>
        <EmailLink title="Copy email to clipboard" onClick={copyEmailToClipboard}>
          email me
          <input
            type="text"
            style={{ position: 'absolute', left: '-999999px' }}
            value="florenciaantara@gmail.com"
            id="floremail"
            ref={emailRef}
            onChange={() => null}
          />
        </EmailLink>
        {/* <TextMeMobile href="sms:‭+12063099510‬" id="textme">
          text me
          <img
            src=" data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAA8CAYAAADRy2JxAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAVISURBVHgB3Zs/bBxVEMZn3q5RkFI4VFFoLsJ0IJmGCjlLA3SkSCSoYtyClEuqFJFiIwoqfESIEic0UCBhulBxjihQKDCC0iguCCAQ+IKQEnx3O8y8uzN7d3u7897u/ctPcu6y99a33775ZmbfrhFKhqrRYhPjC4gYEcEyAlRgOjT4Z5eAvkcy2wu1en1wAEJJPKhGlRBpi99GMIOw0P2A8EWs1fd72wyUwOGllYss/C7MqHCBOAJbSN8dVqPl3rZCMy8h3ja0yeG9CvNDIyQ8zRHQKDTzLUNbcyZcWDzEuCpvvMU3qyvXOJbOwhxiAC/Kq1fYN6tRBEhfwRwT0r+nQ/CAOKurztqJk4BPL8NYePAP0M97AAe/gQ9NOLboLJ6z5SoCVTRjzcurYJ5/BcZJfOcWxF/ecD4JPHmLzp5HpAvqsUtjmvUEcnKDN2sAjx932i+Eh/tO4qWRAW0tP7UE+MRJmATyPealVZddAGvfuIkPHJoYfHIJJok5c85l9ut2H3AADb2qHWueeQEmDT6ls5n0+/LqJF4uVLRjJ+H3IZQzb6BTptXipSfWXqFZ4Y4JqBT+0mX8gIIdeXWZef2sn5qs33vQL3uaYXXp6+WNus5bv5NuLHEDYmuvD3zizLPu+SL+9pZtfPLo+V1Qi+8uTOjG8oEoz1MfZuWcX67gcNeebFnY6L1Xie/4XdfV+WLOvmXFO3NvD1pbV9V+Z8G7ifcqxpe6uf8P1t7x6gvi259BvP2Byy5HfhdU4l387oKEuHmDhbtWhm5OEfEuJP0uqMS7+F2LvejhH2c4vG2Y31Nl9j6SfhdyxZfu92PHwbx+xSuj00+70P7oqiqrp5H0e/f/uZTndy5j1t8eFzw2zH3LZ4c+vwu54svyuy1jHOY+/pbZllkvwqDfhVzxZfh9UmUsi0G/221ZO1i/81o3+FK0jEmYe/p7kIXNnSGteTPv7XfvMsZI7XYtYznU0zZmivf1+zTKWBYx0U7a9kzxzn4vWsY+ebcUfw9iwNTTto/U5uz3ImXMvU11Is3vQtbMq/1epIxZf8vl6Pioj/pgpHit3wv5+8PqWMI8SZviL0Z9liqe1s9D8/7v+X7nUuYlHDoLHsFrV8AHKYHapieAYOTAVPHNxh/s9/z1Ol/hQpGlbfpUHS2NtCcyeqSu4cXQjkDBpNfmBZIyqLdKZnikig+MOQMaprBC69LjZ/ldGBIvfudEF8GMQj98rR6b5XdhSLz4nV8WQXMgP+oPpCwcZj7T74IZ3tCugPZA7oy1Pg9/n0PIj2ppkwyJjxHVj5rITYKCCwxOOIU8wud5Y/rE03rE/6Iu2XUR8bYn//NXgHGsciYg5QWPPHMXbt6+mTeur84/bEAlVNT3oYPi9rTF/pe7pOMsf9qwJ8Bc4UKfeJf770PIMzJ8AqaRBJN0nrSEG5qxfWHvcv99dqH15COmWZj/dznvdP99NsENjdd7HIm3/fz0npAuAdxY2Kyvu+xx5Hnp54NynkOeOER46bFavQaOHKlV9/OzBEGdhT/nI1ywl+x0LYLW33QAyrY283Acv9uDBt+AuDnqDwhcsGHfus8lDgsJJ5kFBLxOnb9wKB35vQv8o83kGqz4NsTLgf+j9wccem/7ht40seIDRJ/6zpOB74cEG4M3AOcF7PrdxasydhcILxf13LQJu37XMrchnkYYQxyZfPWS0LZDwMtlJpxpExrMvoTl03KXZ3tt3kM8DUl40YjPDtjX1/lKrzavCS0PES/NzYnENluzOcTXHqUQTyOUBMY3JN8DaSDY17wQ8PGjGOJp/AfNJCTfNn/ZtgAAAABJRU5ErkJggg=="
            alt="zipwhip"
            width="11"
          />{' '}
        </TextMeMobile> */}
      </Ctas>
    </AppWrapp>
  );
}

export default App;
