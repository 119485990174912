import React, { useRef } from 'react';
import styled from 'styled-components';
import theme from './../theme';

// Images
import avatar_1x from '../images/flor-antara.jpg';
import avatar_2x from '../images/flor-antara@2x.jpg';

// Icons
import { GoMarkGithub } from 'react-icons/go';
import { FaLinkedin, FaDev, FaCodepen } from 'react-icons/fa';
import { FiInstagram } from 'react-icons/fi';

const HeaderWrapper = styled.header`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  min-height: 280px;
  margin-top: 2rem;

  @media (min-width: ${theme.breakpoints.sm}) {
    min-height: 130px;
  }

  @media (min-width: ${theme.breakpoints.md}) {
    justify-content: center;
    padding: 0;
    flex-wrap: initial;
    justify-content: initial;
  }
`;

const Avatar = styled.div`
  height: 118px;
  width: 118px;
  position: relative;
  margin-left: 2rem;
  margin-bottom: 2rem;
  border-radius: 50%;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -4px;
    z-index: -1;
    display: block;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #ee47d4, #ea1884);
    //background: black;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-bottom: 0;
  }

  @media (min-width: ${theme.breakpoints.md}) {
    margin-left: 0;
  }

  img {
    position: relative;
    top: 4px;
    max-width: 100%;
    height: auto;
    border-radius: 50%;
  }
`;

const Meta = styled.div`
  align-self: stretch;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;

  margin-right: 2rem;
  @media (min-width: ${theme.breakpoints.md}) {
    margin-right: 0;
  }
`;

const Name = styled.h1`
  font-size: 27px;
  font-family: ${theme.fontFamilies.serif};
  font-weight: bold;
`;
const Title = styled.h2`
  font-size: 18px;
  font-family: ${theme.fontFamilies.sans_serif};
  padding: 0.3em 0;
`;
const Pronouns = styled.h3`
  font-size: 15px;
  font-family: ${theme.fontFamilies.sans_serif};
  font-weight: bold;
`;

const IconsRow = styled.div`
  margin-top: auto;
`;

const buttonStyles = `
  font-size: 11px;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  text-align: center;
  padding: 8px 5px;
  width: 160px;
  font-family: ${theme.fontFamilies.sans_serif};
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: background-color 100ms linear, border-color 100ms linear, color 100ms linear;
`;

export const DownloadButton = styled.a`
  ${buttonStyles};
  background-color: #4f18ea;
  color: white;
  margin-bottom: 15px;
  flex-grow: 1;

  &:hover {
    background-color: #5c2edf;
    border-color: #5c2edf;
  }

  width: auto;
  border-radius: 0;
  @media (min-width: ${theme.breakpoints.md}) {
    width: 160px;
    border-radius: 25px;
  }
`;

export const EmailLink = styled.a`
  ${buttonStyles};
  flex-grow: 1;
  color: white;
  background-color: black;
  margin-bottom: 15px;

  &:hover {
    background-color: #222;
    border-color: #222;
  }
  width: auto;
  border-radius: 0;

  @media (min-width: ${theme.breakpoints.md}) {
    width: 160px;
    border-radius: 25px;
  }
`;

export const TextMe = styled.a`
  ${buttonStyles};
  flex-grow: 1;
  color: white;
  background-color: black;
  margin-bottom: 15px;
  position: relative;

  &:hover {
    background-color: #222;
    border-color: #222;
  }
  width: auto;
  border-radius: 0;

  @media (min-width: ${theme.breakpoints.md}) {
    width: 160px;
    border-radius: 25px;
  }

  img {
    position: absolute;
    right: 30px;
    top: 7px;
  }
`;

const Ctas = styled.div`
  display: none;

  @media (min-width: ${theme.breakpoints.md}) {
    display: initial;
    position: initial;
    width: auto;
    flex-basis: initial;
    margin-top: initial;
    margin-left: auto;
  }
`;

const SocialIcon = styled.a`
  font-size: 23px;
  margin-right: 0.5em;
  color: black;
  cursor: pointer;
  &:hover {
    color: #4f18ea;
  }
`;

const Header = ({ onShowEmailNotification }) => {
  const emailRef = useRef(null);
  const copyEmailToClipboard = () => {
    emailRef.current.select();
    emailRef.current.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
    onShowEmailNotification(`📃 E-mail copied to clipboard`);
  };
  return (
    <HeaderWrapper>
      <Avatar>
        <img
          src={avatar_2x}
          srcSet={`${avatar_1x} 1x, ${avatar_2x} 2x`}
          alt="Flor Antara - Software Engineer | React, TypeScript, Electron, JAMStack 🍓"
        />
      </Avatar>

      <Meta>
        <Name>Flor Antara</Name>
        <Title>Software Engineer</Title>
        <Pronouns>she/her</Pronouns>
        <IconsRow>
          <SocialIcon
            href="https://github.com/florantara/"
            rel=" noopener noreferrer"
            target="_blank"
          >
            <GoMarkGithub />
          </SocialIcon>
          <SocialIcon
            href="https://www.linkedin.com/in/florenciaantara/"
            rel=" noopener noreferrer"
            target="_blank"
          >
            <FaLinkedin />
          </SocialIcon>
          <SocialIcon
            href="https://codepen.io/florantara"
            rel=" noopener noreferrer"
            target="_blank"
          >
            <FaCodepen />
          </SocialIcon>
          <SocialIcon href="https://dev.to/florantara" rel=" noopener noreferrer" target="_blank">
            <FaDev />
          </SocialIcon>
          <SocialIcon
            href="https://www.instagram.com/techflor/"
            rel=" noopener noreferrer"
            target="_blank"
          >
            <FiInstagram />
          </SocialIcon>
        </IconsRow>
      </Meta>

      <Ctas>
        <EmailLink title="Copy to clipboard" onClick={copyEmailToClipboard}>
          email me
        </EmailLink>
        <input
          type="text"
          style={{ position: 'absolute', left: '-999999px' }}
          value="florenciaantara@gmail.com"
          id="floremail"
          ref={emailRef}
          onChange={() => null}
        />
        {/* <TextMe href="sms:‭+12063099510‬" id="textme">
          text me
          <img
            src=" data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAA8CAYAAADRy2JxAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAVISURBVHgB3Zs/bBxVEMZn3q5RkFI4VFFoLsJ0IJmGCjlLA3SkSCSoYtyClEuqFJFiIwoqfESIEic0UCBhulBxjihQKDCC0iguCCAQ+IKQEnx3O8y8uzN7d3u7897u/ctPcu6y99a33775ZmbfrhFKhqrRYhPjC4gYEcEyAlRgOjT4Z5eAvkcy2wu1en1wAEJJPKhGlRBpi99GMIOw0P2A8EWs1fd72wyUwOGllYss/C7MqHCBOAJbSN8dVqPl3rZCMy8h3ja0yeG9CvNDIyQ8zRHQKDTzLUNbcyZcWDzEuCpvvMU3qyvXOJbOwhxiAC/Kq1fYN6tRBEhfwRwT0r+nQ/CAOKurztqJk4BPL8NYePAP0M97AAe/gQ9NOLboLJ6z5SoCVTRjzcurYJ5/BcZJfOcWxF/ecD4JPHmLzp5HpAvqsUtjmvUEcnKDN2sAjx932i+Eh/tO4qWRAW0tP7UE+MRJmATyPealVZddAGvfuIkPHJoYfHIJJok5c85l9ut2H3AADb2qHWueeQEmDT6ls5n0+/LqJF4uVLRjJ+H3IZQzb6BTptXipSfWXqFZ4Y4JqBT+0mX8gIIdeXWZef2sn5qs33vQL3uaYXXp6+WNus5bv5NuLHEDYmuvD3zizLPu+SL+9pZtfPLo+V1Qi+8uTOjG8oEoz1MfZuWcX67gcNeebFnY6L1Xie/4XdfV+WLOvmXFO3NvD1pbV9V+Z8G7ifcqxpe6uf8P1t7x6gvi259BvP2Byy5HfhdU4l387oKEuHmDhbtWhm5OEfEuJP0uqMS7+F2LvejhH2c4vG2Y31Nl9j6SfhdyxZfu92PHwbx+xSuj00+70P7oqiqrp5H0e/f/uZTndy5j1t8eFzw2zH3LZ4c+vwu54svyuy1jHOY+/pbZllkvwqDfhVzxZfh9UmUsi0G/221ZO1i/81o3+FK0jEmYe/p7kIXNnSGteTPv7XfvMsZI7XYtYznU0zZmivf1+zTKWBYx0U7a9kzxzn4vWsY+ebcUfw9iwNTTto/U5uz3ImXMvU11Is3vQtbMq/1epIxZf8vl6Pioj/pgpHit3wv5+8PqWMI8SZviL0Z9liqe1s9D8/7v+X7nUuYlHDoLHsFrV8AHKYHapieAYOTAVPHNxh/s9/z1Ol/hQpGlbfpUHS2NtCcyeqSu4cXQjkDBpNfmBZIyqLdKZnikig+MOQMaprBC69LjZ/ldGBIvfudEF8GMQj98rR6b5XdhSLz4nV8WQXMgP+oPpCwcZj7T74IZ3tCugPZA7oy1Pg9/n0PIj2ppkwyJjxHVj5rITYKCCwxOOIU8wud5Y/rE03rE/6Iu2XUR8bYn//NXgHGsciYg5QWPPHMXbt6+mTeur84/bEAlVNT3oYPi9rTF/pe7pOMsf9qwJ8Bc4UKfeJf770PIMzJ8AqaRBJN0nrSEG5qxfWHvcv99dqH15COmWZj/dznvdP99NsENjdd7HIm3/fz0npAuAdxY2Kyvu+xx5Hnp54NynkOeOER46bFavQaOHKlV9/OzBEGdhT/nI1ywl+x0LYLW33QAyrY283Acv9uDBt+AuDnqDwhcsGHfus8lDgsJJ5kFBLxOnb9wKB35vQv8o83kGqz4NsTLgf+j9wccem/7ht40seIDRJ/6zpOB74cEG4M3AOcF7PrdxasydhcILxf13LQJu37XMrchnkYYQxyZfPWS0LZDwMtlJpxpExrMvoTl03KXZ3tt3kM8DUl40YjPDtjX1/lKrzavCS0PES/NzYnENluzOcTXHqUQTyOUBMY3JN8DaSDY17wQ8PGjGOJp/AfNJCTfNn/ZtgAAAABJRU5ErkJggg=="
            alt="zipwhip"
            width="15"
          />{' '}
        </TextMe> */}
      </Ctas>
    </HeaderWrapper>
  );
};

export default Header;
